export namespace Constants {

  export class AdvertiseType {
    public static readonly Sell = 1;
    public static readonly Rent = 2;
    public static readonly Investment = 3;
  }

  export class AdvertiseStatus {
    public static readonly Draft = 1;
    public static readonly Published = 2;
    public static readonly Archived = 3;
    public static readonly Completed_Published = 4;
    public static readonly Completed = 5;
    public static readonly Canceled = 6;
  }

  export class AdvertisePublishType {
    public static readonly Public = 1;
    public static readonly Private = 2;
    public static readonly RestrictedAccess = 3;
  }

  export class PropertyTypeCategory {
    public static readonly Land = 1;
    public static readonly TowersAndBuildings = 2;
    public static readonly CommercialProperties = 3;
    public static readonly ResidentialProperties = 4;
    public static readonly HospitalityAndRecreation = 5;
  }

  export class TeamMemberReliability {
    public static readonly High = 1;
    public static readonly Medium = 2;
    public static readonly Low = 3;
  }

  export class ClientRequestPriority {
    public static readonly High = 1;
    public static readonly Medium = 2;
    public static readonly Low = 3;
  }

  export class TeamMemberRequestStatuses {
    public static readonly Waiting = 'Waiting';
    public static readonly Approved = 'Approved';
    public static readonly Rejected = 'Rejected';
  }

  export class InterestRequestStatuses {
    public static readonly New = 1;
    public static readonly Ignored = 2;
    public static readonly Accepted = 3;
  }

  export class SiteAdRequestStatuses {
    public static readonly New = 1;
    public static readonly Ignored = 2;
    public static readonly Accepted = 3;
  }

  export class TeamMembershipType {
    /**
     * موظف
     */
    public static readonly Employee = 1;
    /**
     * متاون 
     */
    public static readonly Agent = 2;
  }

  export class Language {
    public static readonly Arabic = 'ar';
    public static readonly English = 'en';
  }

  export class SubscroptionFeatures {
    /**
     * عدد المستخدمين
     */
    public static readonly NumberOfUsers = 1;

    /**
     * موقع الكتروني خاص
     */
    public static readonly DedicatedWebsite = 2;

    /**
     * العروض العقارية
     */
    public static readonly PropertiesOffers = 3;

    /**
     * طلبات الاهتمام
     */
    public static readonly InterestRequests = 4;

    /**
     * هويتك الخاصة (شعار ولون مخصص)
     */
    public static readonly OwnIdentity = 5;

    /**
     * الصفقات
     */
    public static readonly Deals = 6;

    /**
     * العروض العقارية الخاصة
     */
    public static readonly PrivateRealEstateOffers = 7;

    /**
     * وسيط (Domain) نطاق
     */
    public static readonly WaseitDomain = 8;

    /**
     * نطاق خاص
     */
    public static readonly CustomDomain = 9;

    /**
     * لوحة بيانات تفاعلية
     */
    public static readonly Dashboard = 10;

    /**
     * تحويل الاهتمام إلى طلب
     */
    public static readonly ConvertInterestToRequest = 11;

    /**
     * تصدير بياناتك
     */
    public static readonly ExportYourData = 12;

    /**
     * إدارة الفرق
     */
    public static readonly TeamManagement = 13;

    /**
     * إدارة العقارات
     */
    public static readonly RealEstateManagement = 15;

    /**
     * طلبات العملاء
     */
    public static readonly ClientRequests = 16;

    /**
     * إدارة أهم الصفقات العقارية
     */
    public static readonly ImportantRealEstateDealManagement = 17;

    /**
     * إدارة موثوقية الفرق
     */
    public static readonly TeamReliabilityManagement = 18;

    /**
     * سجل عمليات الفرق
     */
    public static readonly TeamOperationsLog = 19;

    /**
     * إدارة العملاء
     */
    public static readonly ClientsManagement = 20;
  }
}
