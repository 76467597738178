import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configUrl = 'configuration/config.json';
  private config!: AppConfig;

  constructor(private http: HttpClient) {
    this.loadConfig();
  }

  async loadConfig(): Promise<any> {
    const config = await firstValueFrom(this.http.get<AppConfig>(this.configUrl));
    this.config = config;
  }

  getConfig(): AppConfig {
    return this.config;
  }

  getApi() {
    if (this.config) {
      return this.config.api;
    }

    return '';
  }

  getApiHttp() {
    if (this.config) {
      return this.config.apiHttp;
    }

    return '';
  }


  getFilAllowedDocs(): string[] {
    if (this.config) {
      return this.config.filesAllowedDocs;
    }

    return [];
  }

  getCaptchaKey() {
    if (this.config) {
      return this.config.captchaKey;
    }

    return '';
  }

  getCachedLogo() {
    if (this.config) {
      return this.config.cachedLogo;
    }

    return '';
  }

  getBrokerAppBasePath() {
    if (this.config) {
      return this.config.brokerAppBasePath;
    }

    return '';
  }

  getGoogleAnalyticCode() {
    if (this.config) {
      return this.config.brokerAppBasePath;
    }

    return '';
  }

  getEnableDebug() {
    if (this.config) {
      return this.config.enableDebug;
    }

    return false;
  }

  getOtpCount(): number {
    if (this.config) {
      return this.config.otpCount;
    }

    return 120;
  }

  getTermsAndConditions(): string {
    if (this.config) {
      return this.config.termsAndConditions;
    }

    return '';
  }
}

interface AppConfig {
  api: string;
  apiHttp: string;
  fileServer: string;
  brokerAppBasePath: string;
  captchaKey: string;
  googleAnalyticCode: string;
  cachedLogo: string;
  enableDebug: boolean;
  otpCount: number;
  filesAllowedDocs: Array<string>;
  termsAndConditions: string;
}
