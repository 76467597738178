import { inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class MetaService {
    private title = inject(Title);
    private meta = inject(Meta);

    updateMetaTags(tags: MetaTag) {
        this._resetTags();

        this._updateTitleTag(tags.title);
        this._updateDescriptionTag(tags.description);
        this._updateImageTag(tags.imagePath);
    }

    resetMetaTags() {
        this._resetTags();
    }

    //#region Private Methods

    private _updateTitleTag(title: string) {
        if (title) {
            this.title.setTitle(title);
            this.meta.addTag({ name: 'og:title', content: title }); //Facebook
            this.meta.addTag({ name: 'twitter:title', content: title }); //Twitter
        }
    }

    private _updateDescriptionTag(description: string) {
        if (description) {
            this.meta.updateTag({ name: 'description', content: description });
            this.meta.updateTag({ name: 'og:description', content: description }); //Facebook
            this.meta.updateTag({ name: 'twitter:description', content: description }); //Twitter
        }
    }

    private _updateImageTag(imagePath: string) {
        if (imagePath) {
            this.meta.updateTag({ name: 'og:image', content: imagePath.replace(/\\/g, '/') }); //Facebook
            this.meta.updateTag({ name: 'twitter:image', content: imagePath.replace(/\\/g, '/') }); //Twitter
        }
    }

    private _resetTags() {
        this.title.setTitle(undefined);
        this.meta.removeTag("name='description'");

        this.meta.removeTag("name='og:title'"); //Facebook
        this.meta.removeTag("name='og:description'"); //Facebook
        this.meta.removeTag("name='og:image'"); //Facebook
        
        this.meta.removeTag("name='twitter:title'"); //Twitter
        this.meta.removeTag("name='twitter:description'"); //Twitter
        this.meta.removeTag("name='twitter:image'"); //Twitter
    }

    //#endregion
}

export interface MetaTag { title?: string, description?: string, imagePath?: string }