import { isPlatformServer, PlatformLocation } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";
import { SiteInformationResult } from "../../openapi";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SiteService {
    private platformId = inject(PLATFORM_ID);
    private siteKeyService = inject(SiteKeyService);
    private platformLocation = inject(PlatformLocation);

    private siteInformation: SiteInformationResult;

    extractKey(): string {
        return this.siteKeyService.extractKey();
    }

    getInformation() {
        if (isPlatformServer(this.platformId)) {
            return this.siteInformation;
        }

        const siteKey = this.extractKey();
        const data = JSON.parse(localStorage.getItem(siteKey)!) as SiteInformationResult;

        if (data) {
            data.logoPath = this.getLogoPath(data.logoPath);
        }

        return data;
    }

    getLogoPath(logo?: string) {
        const origin = isPlatformServer(this.platformId)
            ? `${this.platformLocation.protocol}//${this.platformLocation.hostname}:${this.platformLocation.port}`
            : window.origin;

        return logo
            ? `${logo}`
            : `${origin}/assets/images/logos/Full_Color_Black.png`;
    }

    hasKey() {
        const key = this.extractKey();
        return key !== null && key !== undefined && key !== '' && key !== '404';
    }

    private dataSubject = new BehaviorSubject<SiteInformationResult | undefined>(undefined);
    dataSiteInformation = this.dataSubject.asObservable();

    setSiteInformation(siteInfo: SiteInformationResult) {
        this.dataSubject.next(siteInfo);
    }

    getSiteInformation() {
        return this.dataSubject.value;
    }
}

@Injectable({ providedIn: 'root' })
export class SiteKeyService {
    private platformLocation = inject(PlatformLocation);

    extractKey(): string {
        const segments = this.platformLocation.pathname.split('/');
        const siteKey = segments[2]; // Assuming the site key is the third segment;
        return siteKey;
    }
}